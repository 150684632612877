var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('div', [_vm.$permissionAbility(_vm.ATTENDANCE_SEARCH, _vm.permissions) ? [_c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterEmpIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Employee"
    },
    on: {
      "input": function input($event) {
        return _vm.loadAttendance('user');
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }], null, false, 3839851139),
    model: {
      value: _vm.selectEmpId,
      callback: function callback($$v) {
        _vm.selectEmpId = $$v;
      },
      expression: "selectEmpId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterStatusIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Status"
    },
    on: {
      "input": function input($event) {
        return _vm.loadAttendance('status');
      }
    },
    model: {
      value: _vm.selectStatusId,
      callback: function callback($$v) {
        _vm.selectStatusId = $$v;
      },
      expression: "selectStatusId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterDivisionIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Division"
    },
    on: {
      "input": function input($event) {
        return _vm.loadAttendance('division');
      }
    },
    model: {
      value: _vm.selectDivisionId,
      callback: function callback($$v) {
        _vm.selectDivisionId = $$v;
      },
      expression: "selectDivisionId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterDepartmentIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Department"
    },
    on: {
      "input": function input($event) {
        return _vm.loadAttendance('department');
      }
    },
    model: {
      value: _vm.selectDepartmentId,
      callback: function callback($$v) {
        _vm.selectDepartmentId = $$v;
      },
      expression: "selectDepartmentId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterEmpTypeIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Employee Types"
    },
    on: {
      "input": function input($event) {
        return _vm.loadAttendance('empType');
      }
    },
    model: {
      value: _vm.selectEmpTypeId,
      callback: function callback($$v) {
        _vm.selectEmpTypeId = $$v;
      },
      expression: "selectEmpTypeId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Select Date Range",
      "config": {
        mode: 'range',
        defaultDate: [_vm.filterStartDate, _vm.filterEndDate]
      }
    },
    on: {
      "input": function input($event) {
        return _vm.loadAttendance('date');
      }
    },
    model: {
      value: _vm.filterRangeDate,
      callback: function callback($$v) {
        _vm.filterRangeDate = $$v;
      },
      expression: "filterRangeDate"
    }
  })], 1)], 1)], 1)] : [_c('div', {
    staticClass: "mb-2"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Enter Date Range",
      "config": {
        mode: 'range',
        defaultDate: [this.filterStartDate, this.filterEndDate]
      }
    },
    on: {
      "input": function input($event) {
        return _vm.loadAttendance('date');
      }
    },
    model: {
      value: _vm.filterRangeDate,
      callback: function callback($$v) {
        _vm.filterRangeDate = $$v;
      },
      expression: "filterRangeDate"
    }
  })], 1)], 1)], 1)]], 2)]), _c('b-card', [_c('div', [_c('vue-good-table', {
    attrs: {
      "style-class": "vgt-table table-custom-style striped",
      "mode": "remote",
      "total-rows": _vm.totalRecords,
      "is-loading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'date',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row2, _props$row2$user, _props$row3, _props$row4, _props$row5, _props$row6, _props$row7, _props$row8, _props$row9, _props$row10, _props$row11, _props$row11$attendan, _props$row11$attendan2, _props$row$attendance, _props$row12, _props$row12$attendan, _props$row12$attendan2, _props$row13, _props$row14;
        return [props.column.field === 'name' ? _c('span', [(_props$row = props.row) !== null && _props$row !== void 0 && _props$row.user_id ? [_c('span', {
          staticClass: "cursor-pointer",
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_c('UserCard', {
          attrs: {
            "user": props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$user = _props$row2.user) === null || _props$row2$user === void 0 ? void 0 : _props$row2$user.data
          }
        })], 1)] : _vm._e()], 2) : _vm._e(), props.column.field === 'format_check_in_out' ? _c('span', {
          staticClass: "font-weight-bold"
        }, [(_props$row3 = props.row) !== null && _props$row3 !== void 0 && _props$row3.first_check_in ? [_c('div', [_c('span', [_vm._v(" Check In : "), _c('b', [_vm._v(" " + _vm._s(_vm.formatFnTableCheckIn((_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.first_check_in)) + " ")])])])] : _vm._e(), (_props$row5 = props.row) !== null && _props$row5 !== void 0 && _props$row5.last_check_out ? [_c('div', [_c('span', [_vm._v(" Check Out : "), _c('b', [_vm._v(" " + _vm._s(_vm.formatFnTableCheckIn((_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : _props$row6.last_check_out)) + " ")])])])] : _vm._e()], 2) : _vm._e(), props.column.field === 'format_extra_less_duty_minutes' ? _c('span', [((_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : _props$row7.extra_less_duty_minutes) < 0 ? [_c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-danger",
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.formatFnTableExtraLessDutyHours((_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.extra_less_duty_minutes)) + " ")])], 1)] : parseInt((_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : _props$row9.extra_less_duty_minutes) === 0 ? [_c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-info",
            "pill": ""
          }
        }, [_vm._v(" 00:00 ")])], 1)] : [_c('div', [_c('b-badge', {
          attrs: {
            "variant": "light-success",
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.formatFnTableExtraLessDutyHours((_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : _props$row10.extra_less_duty_minutes)) + " ")])], 1)]], 2) : _vm._e(), props.column.field === 'status' ? _c('span', [(_props$row11 = props.row) !== null && _props$row11 !== void 0 && (_props$row11$attendan = _props$row11.attendanceStatus) !== null && _props$row11$attendan !== void 0 && (_props$row11$attendan2 = _props$row11$attendan.data) !== null && _props$row11$attendan2 !== void 0 && _props$row11$attendan2.name ? [_c('b-badge', {
          staticClass: "badge badge-glow",
          style: {
            color: 'white',
            backgroundColor: (_props$row$attendance = props.row.attendanceStatus.data.color_code) !== null && _props$row$attendance !== void 0 ? _props$row$attendance : '#7367f0'
          }
        }, [_vm._v(" " + _vm._s((_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : (_props$row12$attendan = _props$row12.attendanceStatus) === null || _props$row12$attendan === void 0 ? void 0 : (_props$row12$attendan2 = _props$row12$attendan.data) === null || _props$row12$attendan2 === void 0 ? void 0 : _props$row12$attendan2.name) + " ")])] : _vm._e(), (_props$row13 = props.row) !== null && _props$row13 !== void 0 && _props$row13.half_day_leave ? [_c('div', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "light-danger",
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm._f("strSnakeToPascal")((_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : _props$row14.half_day_leave)) + " Leave ")])], 1)] : _vm._e()], 2) : _vm._e(), props.column.field === 'action' ? _c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            },
            "contextmenu": function contextmenu($event) {
              $event.preventDefault();
              return _vm.onOpenInNewTab(props.row.id);
            }
          }
        }, [_vm.$permissionAbility(_vm.ATTENDANCE_SHOW, _vm.permissions) ? [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "EyeIcon",
            "title": "View",
            "size": "16"
          }
        })] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }